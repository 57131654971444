.popup-toast {
  position: absolute;
  top: 20px;
  right: 20px;
  min-width: 200px;
  z-index: 1000;

  &__header {
    justify-content: space-between;
  }
}

.btn-close {
  margin: 0 !important;
  width: 14px;
  height: 14px;
}

@media screen and (min-width: 360px) and (max-width: 768px) {
  .popup-toast {
    top: 100px;
    right: 40px;
  }
}
